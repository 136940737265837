<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate" id="usrsetupcc">
      <div class="row justify-content-center centerform">
        <div class="col-sm-12" v-if="!agencyManualBill">
          <h5 class="info-text">Your Billing Information</h5>
          <h5 v-if="this.emmplatform">Terms and conditions apply. <br/>60 days no minimum spend, then 12 month plan of minimum $149/month spend.</h5>
        </div>
        
        <div v-if="!agencyManualBill" id="card-element" class="col-sm-12 col-md-12 col-lg-12 card-border">&nbsp;</div> <div class="pt-2 pl-2" v-if="cardfailetoload" style="font-size:0.80143rem"><i class="fas fa-sync-alt pr-2" @click="refreshCCfield();" style="cursor:pointer"></i>Failed to load the Credit Card field. Please <a href="javascript:void(0);" @click="refreshCCfield();" style="font-weight:bold">click here</a> to refresh and try again.</div>
        <div v-if="!agencyManualBill" id="carderror" class="col-sm-12 col-md-12 col-lg-12 pt-2 hide" style="color:red"><small>&nbsp;</small></div>

        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-3">
          <ValidationProvider
            name="Card Holder Name"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="cardholdername"
            label="Card Holder Name"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>

        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-2">
                <base-radio name="sameCompanyAddr" v-model="radios.billaddresssame" @change="chksameaddresswith" inline>Use company mailing address for the billing address.</base-radio>
        </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-2">
                <base-radio name="sameCustAddr" v-model="radios.billaddresssame" @change="chksameaddresswith" inline>Use personal mailing address for the billing address.</base-radio> 
        </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 pt-2">
          <ValidationProvider
            name="billingAddress"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="billingaddress"
            label="Billing Address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 mt-2">
            <div class="country-region-select-wrapper">
              <span  class="country-label">Country *</span>
              <country-select  class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
              <span v-if="showErrorMessage && !selects.country" style="color: red;">Please select country</span>
            </div> 
         </div>
        <div v-if="!agencyManualBill" class="col-sm-12 col-md-12 col-lg-12 mt-2">
          <div v-if="selects.country" class="country-region-select-wrapper">
          <span class="country-label">{{ selects.country === "CA" ? "Province *" : "State *" }}</span>
          <region-select  class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state"  />
          <span v-if="showErrorMessage && !selects.state" style="color: red;">Please select state</span>
          </div>
        </div>
       
          <div v-if="!agencyManualBill" class="col-sm-6 col-md-6 col-lg-6 pt-2">
              <ValidationProvider
                name="City"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
                <base-input
                  v-model="city"
                  label="City"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>
          <!-- <div class="col-sm-4 col-md-4 col-lg-4 pt-2">
             <label>State</label>
             <base-input>
                <el-select
                  id="stateid"
                  v-model="selects.state"
                  class="select-primary "
                  name="state"
                  inline
                  size="large"
                  filterable
                  default-first-option
                  >
                  <el-option
                    v-for="option in selects.statelist"
                    class="select-primary"
                    :label="option.state"
                    :value="option.state_code"
                    :key="option.state_code"
                  ></el-option>
                </el-select>
             </base-input>
          </div> -->
          <div v-if="!agencyManualBill" class="col-sm-6 col-md-6 col-lg-6 pt-2">
            <ValidationProvider
                name="zipcode"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
              <base-input
                  v-model="zipcode"
                  label="Zip"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>
        

        <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                <div class="or-container">
                    <div class="line-separator"></div>
                    <div class="or-label">Service and Billing Agreement</div>
                    <div class="line-separator"></div>
                </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12">
            <!-- SERVICE BILLING AGREEMENT -->
            <iframe ref="iframeRef" src="/service-billing-agreement" width="100%"  height="400vh" @load="onIframeLoad" style="border-radius: 8px;" />
            <!-- SERVICE BILLING AGREEMENT -->
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 ">
                <base-checkbox :disabled="agreeTermDisable" v-model="agreeTermBilling" class="pull-left" :class="{'has-danger': agreeTermStatBilling}">I read and Agree with Service and Billing Agreement</base-checkbox> 
        </div>
         <div class="col-sm-12 col-md-12 col-lg-12 " style="padding-top:15px">
           <a href="/service-billing-agreement/" target="_blank" style="font-weight:bold;text-decoration:underline">Click here to view service and billing agreement page</a>  
         </div>
        <!--<div class="col-sm-12 col-md-12 col-lg-12 ">
          <base-checkbox :disabled="agreeTermDisable" v-model="agreeTerm" class="pull-left" :class="{'has-danger': agreeTermStat}" >I Agree with <a href="#termofbilling" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[agreeTermStat?{'color':'#ec250d'}:'']" v-on:click.stop.prevent="modals.termofbilling = true">Term of Billing</a></base-checkbox>
        </div>-->
      </div>
    </form>
   <!-- MODAL ERROR WARNING-->
    <modal :show.sync="modals.errorbilling" headerClasses="justify-content-center" modalContentClasses="modal-errorbilling">
      <h4 slot="header" class="title title-up">Error</h4>
      <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
          <span class="swal2-x-mark-line-left"></span>
          <span class="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <h2 class="swal2-title" id="swal2-title" style="display: block;">Fields marked in red must be filled in completely.</h2>
      <div class="swal2-html-container text-center" id="swal2-html-container" style="display: block;">&nbsp;</div>
      <template slot="footer">
        <div class="container text-center pt-4 pb-4">
          <base-button class="btn btn-secondary" style="min-width:200px" @click="modals.errorbilling = false;">Ok</base-button>
        </div>
      </template>
    </modal>
    <!-- MODAL ERROR WARNING-->

    <!-- MODAL TERM OF BILLING-->
    <modal :show.sync="modals.termofbilling" headerClasses="justify-content-center" id="modaltermofbilling">
              <h4 slot="header" class="title title-up">TERMS of Billing</h4>
              <div class="termofbilling">
                <p>Revised: April 1, 2022</p>
                <ul style="list-style-type:decimal">
                  <li>Acceptance of Terms.
                    <p><strong>By using this Website, you agree to the Terms of Use</strong> (the “Terms”).</p>
                    <p>The use of “we,” “our,” and “us” herein refers to the {{CompanyName}} Marketing, LLC, and “you” and “your” refers to you as the consumer utilizing this Website (“Website”). We maintain this Website to offer Advertising, Marketing and Lead Generation services, among other things.</p>
                    <p>Please read these Terms carefully, as they create a legally binding agreement between you and us regarding your use of the Website. By using the Website, you are agreeing to be legally bound by these Terms. <strong>If you do not agree to follow these Terms, do not use the Website.</strong></p>
                    <p>Your use of this Website is subject to our Privacy Policy, which is hereby incorporated into the Terms by this reference.</p>
                  </li>
                  <li>Using the Website; Authorized Uses.
                    <p>You should only use the Website if you are authorized or it is otherwise legal for you to do so. You agree these Terms and our Privacy Policy govern your use of the Website and the services we offer you through the Website. You also agree to abide by all applicable local, state, federal, and international laws and regulations while using the Website. If you breach these Terms, you are solely responsible for any damages that result therefrom. You are solely responsible for your actions while using the Website and any damages that result. You are responsible for any software, information, data, files, images, documents, text or other material you post, submit or transmit through the Website (“Materials”), including for any damages resulting therefrom.</p>
                  </li>
                  <li>User Submissions.
                    <p>If you post, submit, or transmit Materials on or through the Website, you warrant that:</p>
                      <blockquote>
                        <p>a) The Materials contain truthful, accurate, and complete information and you agree to update information as is necessary to make sure it remains truthful, accurate, and complete;</p>
                        <p>b) You own or control the rights to the Materials and the Materials do not contain copyrighted or protected information;</p>
                        <p>c) You are liable for damages caused by infringement and agree to indemnify us for any third-party damages resulting from such infringement;</p>
                        <p>d) The Materials do not violate the Terms of Use and will not injure another person; and</p>
                        <p>e) Materials posted, submitted, and transmitted are not confidential.</p>
                      </blockquote>
                    
                    <p>You are solely liable for any damages resulting from Materials you post, submit or transmit through the Website. We disclaim all liability for user posts, submissions and transmission on the Website. We do not ratify, subscribe to, or advocate for your views, opinions, recommendations, advice, or theories. We do not preview Materials posted, submitted, or transmitted through the Website. We may monitor, edit, or remove Materials and/or terminate your access to the Website or any part of the Website based on the Materials you post, submit or transmit.</p>
                    <p>By posting, submitting, or transmitting Materials on or through the Website, you grant us an  irrevocable, perpetual, non-exclusive, royalty free, worldwide license to use, sublicense, reproduce, adapt, modify, translate, publish, distribute, perform, or publicly display all or any portion of the Materials, subject to our Privacy Policy, without attributing the Materials to you. You agree that we are not bound by any confidentiality requirements for these Materials.</p>
                    <p>We may use the Materials you post, submit, or transmit and any techniques, ideas, or concepts found therein, in any way and for any purpose. We may transmit or distribute the Materials on public networks or other media, and make such Materials available to other companies, organizations, or individuals. We may make changes to Materials as necessary to conform or adapt Materials to technical requirements of public networks or media.</p>
                  </li>
                  <li>Intellectual Property Rights.
                    <p>This Website contains our intellectual property. In some instances, the Website may contain the intellectual property of third parties. All intellectual property is protected by U.S. and international copyright law and other intellectual property laws. You may not reproduce or distribute the Website content in any way without our express written consent. Doing so may result in civil and criminal penalties, and you will be prosecuted to the maximum extent possible under law. The publication or dissemination of any link to the contents of this Website, other than a link to <a href="https://exactmatchmarketing.com" target="_blank">https://exactmatchmarketing.com</a>, without our express written consent is prohibited.</p>
                    <p>We grant you a personal, non-transferable, non-exclusive, limited, and freely revocable license to view, download, or print content available on the Website. However, we may limit your ability to access, view, download, or print content at our sole discretion, or completely revoke this license with reasonable notice to you. The content you access, view, download, or print may be subject to other licenses and you agree to abide by those licenses.</p>
                    <p>Nothing stated herein shall be construed to confer any rights to our (or any third party’s rights to) intellectual property, whether by estoppel, implication, waiver, or otherwise, except where expressly stated. You agree not to change, remove, or deface any of the copyright notices, trademarks, service marks, or other intellectual property made available by us in connection with this Website, including if you download or print content from the Website. You agree not to use any of the trademarks, service marks, or other content accessible through the Website for any purpose not intended by us. You agree not to use any of the trademarks, service marks, or other content accessible through the Website in any manner that is likely to confuse others about who owns such trademarks, service marks, or other content. You agree not to defame or disparage us, our trademarks or service marks, or any aspect of this Website. Unless otherwise stated, you agree not to copy, adapt, change, translate, decompile, disassemble, reverse engineer, or create derivative works of this Website, any software or other features used in connection with or made available through your use of this Website.</p>
                  </li>
                  <li>Copyright Infringement Notification.
                    <p>If you believe that any part of our Website or any content thereon infringes on another’s copyright, please notify us, in writing, with the following information: </p>
                         <blockquote>
                           <p>1) Identification of the copyrighted work claimed to have been infringed, or identification of a representative list of such works if multiple copyrighted works are involved;</p>
                           <p>2) Description of the location of an original or an authorized copy of the copyrighted work (i.e., a URL);</p>
                           <p>3) Description of the location of the allegedly infringing material on our Website (i.e., a URL);</p>
                           <p>4) Your contact information, including your address, telephone number, and an electronic mail address, if available;</p>
                           <p>5) A statement by you that you believe, in good faith, that the use of the material at issue is not authorized by the copyright owner, its agent, or the law;</p>
                           <p>6) A statement by you, under penalty of perjury, that the information you are providing is accurate and that you are either the copyright owner or that you are authorized to act for the copyright owner; and</p>
                           <p>7) The signature (physical or electronic) of the copyright owner or the person authorized to act for the copyright owner.</p>
                         </blockquote>
                   
                    <p>Please send your written communication to:</p>
                    <p>{{CompanyName}} Marketing, LLC</p>
                    <p>copyright(at)exactmatchmarketing.com</p>
                    <p>If you knowingly and materially misrepresent that our Website content or activity infringes upon another’s copyright, you may be liable for damages, including for costs and attorneys’ fees incurred by the accused infringer, the copyright owner or their agent, or a service provider or other person who is damaged therefrom, due to reliance on the misrepresentation, the removal or disablement of the allegedly infringing material, or the replacement of the allegedly infringing material, pursuant to 17 U.S.C. § 512(f).</p>
                    <p>This information is provided as a courtesy and should not be considered legal advice.</p>
                  </li>
                </ul>
              </div>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button @click="agreedtermofbilling();">Yes, I Understand</base-button>
                </div>
              </template>
            </modal>
      <!-- MODAL TERM OF BILLING -->
  </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui';
import { Modal,BaseCheckbox,BaseRadio } from '/src/components';
import BaseInput from '../../../../../../components/Inputs/BaseInput.vue';

var _elements;
var _cardElement;
var _stripe;
var _CardValidate;
var _tokenid = '';
var _sppubkey = '';
var _this;

export default {
  components: {
    Modal,
    BaseCheckbox,
    [Option.name]: Option,
    [Select.name]: Select,
    BaseRadio,
  },
  data() {
    BaseInput
    return {
      agencyManualBill:false,
      showErrorMessage:false,
      cardfailetoload:false,
      CompanyName: 'Exact Match Marketing',
      CompanyAddress: '',
      CompanyPrivacyPolicy: '',
      CompanyTermsofuse: '',
      CompanyWeb: '',
      CompanyEmail: '',
      CustomerCareEmail: '',
      CustomerCareName: '',
      CustomerCompanyName: '',
      CustomerCompanyEmail: '',
      CustomerEmail: '',
      CustomerName: '',
      CustomerAddress: '',
      user: '',

      cardholdername: '',
      billingaddress: '',
      city: '',
      zipcode: '',
      selects: {
        state: '',
        country: '',
        statelist: [],
      },
      ccmonth:'',
      ccyear:'',
      radios: {
        billaddresssame:'',
      },
      modals: {
        termofbilling:false,
        errorbilling:false,
      },
      agreeTermDisable:true,
      agreeTermBilling:false,
      agreeTermStatBilling:false,
      agreeTerm:false,
      agreeTermStat:false,

      platform: false,
      platformagency: false,
      emmplatform:false,
      intervalId: null,
    };
  },
  methods: {
    refreshCCfield() {
      if (this.$global.sppubkey != "") {
        _sppubkey = this.$global.sppubkey;
        this.initstripelib();
        //this.cardfailetoload = false;
      }else{
        this.$store.dispatch('getGeneralSetting', {
              companyID: this.user.company_root_id,
              settingname: 'rootstripe',
          }).then(response => {
            _sppubkey = response.data;
            this.initstripelib();
            //this.cardfailetoload = false;
          },error => {
            this.$notify({
              type: 'primary',
              message: 'Please try to refresh the page',
              icon: 'fas fa-bug'
            })
            this.cardfailetoload = true;    

          });
      }
    },
    agreedtermofbilling() {
      this.agreeTerm = true;
      this.agreeTermDisable = false;
      this.modals.termofbilling = false;
    },
    termscrolltop() {
      this.$refs.termsandcond.scrollTop = 0;
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      //console.log(Math.ceil(scrollTop) + ' | ' + clientHeight + ' | ' + scrollHeight);
      if (Math.ceil(scrollTop) + (clientHeight + 20) >= scrollHeight) {
        this.agreeTermDisable = false;
      }
    },
    onIframeLoad(){
      let iframe = this.$refs.iframeRef;
      try {
        let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        if(iframeDoc){
          const style = iframeDoc.createElement('style');
          style.innerHTML = '.full-page > .content { padding-top: 0 !important; padding-bottom: 40px !important; }';
          iframeDoc.head.appendChild(style);
        }

        iframeDoc.addEventListener("scroll", () => {
          let scrollHeight = iframeDoc.documentElement.scrollHeight;
          let scrollTop = iframeDoc.documentElement.scrollTop;
          let clientHeight = iframeDoc.documentElement.clientHeight;

          if (scrollTop + clientHeight >= scrollHeight - 10) {
            this.agreeTermDisable = false;
          }
        });
      } catch (error) {
        console.warn("cross-origin restriction.");
      }
    },
    initstripelib() {
      var chkload = this.$global.stripescriptload;
      if (chkload === false) {
        this.$global.initStripeScriptLoad(true);
        $.getScript( "https://js.stripe.com/v3/" )
        .done(function( script, textStatus ) {
            initcreditcard();
        })
        .fail(function( jqxhr, settings, exception ) {
            //$( "div.log" ).text( "Triggered ajaxError handler." );
        });
      }else{
        initcreditcard();
      }
      
    },
    chksameaddresswith() {
      
      if (this.radios.billaddresssame == "sameCompanyAddr") {
        this.billingaddress = this.$store.getters.userData.company_address
        this.city = this.$store.getters.userData.company_city
        this.selects.state = this.$store.getters.userData.company_state_code
        this.selects.country = this.$store.getters.userData.company_country_code
        this.zipcode = this.$store.getters.userData.company_zip
      }else{
        this.billingaddress = this.$store.getters.userData.address
        this.city = this.$store.getters.userData.city
        this.selects.state = this.$store.getters.userData.state_code
        this.selects.country = this.$store.getters.userData.country_code
        this.zipcode = this.$store.getters.userData.zip
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    makeFinishSetup() {
      var user = this.$store.getters.userData
      this.$store.dispatch('userSetupComplete', {
          usrID: user.id,
          statuscomplete: 'T',
      }).then(response => {
          //console.log(response.params)
          /*this.$store.dispatch('setUserData', {
              user: response.params,
          });*/
          this.$store.dispatch('retrieveUserData',{
                usrID: user.id,
          });
          this.$global.creditcardsetup = true;
      },error => {
          
      });
    },
    ccsetupfinish() {
      var user = this.$store.getters.userData
      this.$store.dispatch('retrieveUserData',{
                usrID: user.id,
      });
      this.$global.creditcardsetup = true;
    },
    showErrorModal() {
      this.modals.errorbilling = true;
    },
    createStripeCustomer() {
      var _store = this.$store;
      var _this = this;
      var _user = this.$store.getters.userData
      return new Promise((resolve, reject) => {
        _stripe.createToken(_cardElement).then(function(result) {
            if(result.error) {
                $('.btnNext2').html('Next');
                $('.btnNext2').attr('disabled',false);
                console.log(result.error.message);
                $('#carderror').html('<small style="color:red"><sup>*</sup>' + result.error.message + '</small>');
                $('#carderror').show();
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
                _this.showErrorModal();
            }else{
                $('#carderror').hide();
                _tokenid = result.token.id;
                _store.dispatch('paymentcustomersetup', {
                    tokenid: _tokenid,
                    cardholder: _this.cardholdername,
                    address: _this.billingaddress,
                    city: _this.city,
                    state: _this.selects.state,
                    country: _this.selects.country,
                    zip: _this.zipcode,
                    usrID: _user.id,
                    action: '',
                }).then(response => {
                    //console.log(response.result)
                    if(response.result == 'success') {
                      //_this.makeFinishSetup();
                      _this.ccsetupfinish();
                      if (_user.user_type == 'client') {
                        $('.btnNext2').html('Go To Dashboard');
                      }else{
                        $('.btnNext2').html('Go To General Settings');
                      }
                      $('.btnNext2').attr('disabled',false);
                      $('html, body').animate({
                          scrollTop: 0
                      }, 500);
                      resolve(true);   
                    }
                },error => {
                    $('.btnNext2').html('Next');
                    $('.btnNext2').attr('disabled',false);
                    $('#carderror').html('<small style="color:red"><sup>*</sup>The credit card has expired, reached its limit, or is not being authorized as a valid credit card</small>');
                    $('#carderror').show();
                    $('html, body').animate({
                        scrollTop: 0
                    }, 500);
                     _this.showErrorModal();
                });
            }
        });
      });
      /*token = _stripe.createToken(_cardElement).then(function(result) {
            if(result.error) {
                console.log(result.error.message);
            }else{
              _tokenid = result.token.id;
              //console.log('Token : ' + _tokenid);
                _store.dispatch('paymentcustomersetup', {
                    tokenid: _tokenid,
                }).then(response => {
                    //console.log(response.result)
                    if(response.result == 'success') {
                      //_this.makeFinishSetup();
                     
                      
                    }
                },error => {
                    
                });

            }
      });*/
    },
    getStateList() {
      this.$store.dispatch('getStateList').then(response => {
          this.selects.statelist = response.params
      },error => {
          
      });
    },
    validate() {
      if (this.user.user_type != 'client' || (this.user.manual_bill == 'F' && this.user.user_type == 'client')) {
        if (typeof(_cardElement) == "undefined" || _sppubkey == "") {
          this.cardfailetoload = true;
          return false;
        }

        if (this.selects.country == '') {
                this.$notify({
                  type: 'primary',
                  message: 'Please select country',
                  icon: 'fas fa-bug'
                })
                this.showErrorMessage = true;
              }
        if (this.selects.state == '') {
          this.$notify({
            type: 'primary',
            message: 'Please select state',
            icon: 'fas fa-bug'
          })
          this.showErrorMessage = true;
          // $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-danger');
          // $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-success');
        }else{
          $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-danger');
          $('#usrsetupcc #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-success');
        }

        /*this.agreeTermStat = false;
        if (this.agreeTerm == false) {
            this.agreeTermStat = true;
        }*/
        this.agreeTermStatBilling = false;
        if (this.agreeTermBilling == false) {
          this.agreeTermStatBilling = true;
        }

        return this.$refs.form.validate().then(res => {
          if(res) {
            this.agreeTermStatBilling = false;
            if (this.agreeTermBilling == false) {
              this.agreeTermStatBilling = true;
              $('html, body').animate({
                  scrollTop: 0
              }, 500);
              this.modals.errorbilling = true;
              return false;
            }
            
            /*this.agreeTermStat = false;
            if (this.agreeTerm == false) {
              this.agreeTermStat = true;
              return false;
            }*/
            if (this.selects.state !== '' && this.selects.country != '' && (typeof(_cardElement) != "undefined" && _sppubkey != "")) {
              //this.createStripeCustomer();
              $('.btnNext2').html('Processing...')
              $('.btnNext2').attr('disabled',true);
              this.$emit("on-validated", res,this.$data);
              return this.createStripeCustomer();
            }else{
              this.showErrorMessage = true;
            }
            
            /*this.makeFinishSetup();
            this.$emit("on-validated", res,this.$data);
            return res;
            */
          }else{
            //this.showpoperror();
            $('html, body').animate({
              scrollTop: 0
            }, 500);
            this.modals.errorbilling = true;
          }
        });
      }else{
            this.agreeTermStatBilling = false;
            if (this.agreeTermBilling == false) {
              this.agreeTermStatBilling = true;
            }
            
            if (this.agreeTermBilling) {
              $('.btnNext2').html('Processing...')
              $('.btnNext2').attr('disabled',true);
              return new Promise((resolve, reject) => {
                  this.$store.dispatch('paymentdirectcustomersetup', { 
                        usrID: this.user.id,
                  }).then(response => {
                        //console.log(response.result)
                        if(response.result == 'success') {
                          this.ccsetupfinish();
                          if (this.user.user_type == 'client') {
                            $('.btnNext2').html('Go To Dashboard');
                          }
                          $('.btnNext2').attr('disabled',false);
                        
                          $('html, body').animate({
                              scrollTop: 0
                          }, 500);
                          
                          resolve(true);
                        }
                    },error => {
                        
                    });
              });
            }
      }

    }
  },
  watch: {
    agreeTermBilling: function(value) {
      if (value == true) {
        this.agreeTermStatBilling = false;
      }
    },
    /*agreeTerm: function(value) {
      if (value == true) {
        this.agreeTermStat = false;
      }
    },*/
  },
  mounted() {
    this.user = this.$store.getters.userData
    if (this.user.manual_bill == 'T' && this.user.user_type == 'client') {
      this.agencyManualBill = true;
    }else{
      this.agencyManualBill = false;
    }
    //console.log(user);
    if (this.user.user_type == 'userdownline') {
       this.CompanyName = this.user.company_name;
       this.CompanyAddress = this.user.company_address + ', ' +  this.user.company_city + ', ' +  this.user.company_state_name + ' ' +  this.user.company_zip;
       this.CompanyEmail = this.user.company_email;
       this.CustomerName = this.user.name;
       this.CustomerEmail = this.user.email;
    }else{
      this.CompanyName = this.user.companyparentname;
      this.CompanyAddress = this.user.companyparentaddress + ', ' +  this.user.companyparentcity + ', ' +  this.user.companyparentstate + ' ' +  this.user.companyparentzip;
      this.CompanyEmail = this.user.companyparentemail;
      this.CustomerCareEmail = this.user.customercare;
      this.CustomerCareName = this.user.customercarename;
      this.CustomerCompanyName = this.user.company_name;
      this.CustomerCompanyEmail = this.user.company_email;
      this.CustomerName = this.user.name;
      this.CustomerAddress = this.user.address + ', ' +  this.user.city + ', ' +  this.user.state_name + ' ' +  this.user.zip;
    }

    this.CompanyWeb = document.location.hostname;
    this.CompanyPrivacyPolicy = 'https://' + this.CompanyWeb + '/privacy-policy';
    this.CompanyTermsofuse = 'https://' + this.CompanyWeb + '/term-of-use';
   
    if (typeof(this.user.user_type) == 'undefined' || this.user.user_type == 'client') {
       this.platformagency = true;
    }else{
      if(this.user.user_type == 'userdownline' && this.$global.agencyplatformroot == true) {
        this.emmplatform = true;
      }else{
        this.platform = true;
      }
    }

    _sppubkey = this.$global.sppubkey;
    _this = this;
    this.agreeTermDisable = true;
    this.cardfailetoload = false;
    if (typeof(_sppubkey) == "undefined" || _sppubkey == "") {
      this.cardfailetoload = true;
    }else{
      this.initstripelib();
    }
    this.getStateList();
    
  }
};

function initcreditcard() {
  if (!$('#card-element').length) {
    return
  }

	_stripe = Stripe(_sppubkey);
	/** ATTACHED CARD ELEMENTS */
   _elements = _stripe.elements();
    _cardElement = _elements.create('card', {
    hidePostalCode: true,   
    style: {
        base: {
               color: 'rgba(82, 95, 127, 0.8)',
                fontSize: '16px',
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: 'rgba(82, 95, 127, 0.3)',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                  color: 'rgba(82, 95, 127, 0.3)',
                },
            },
        },
    });
    _this.cardfailetoload = false;

    if (typeof(_cardElement) == "undefined") {
      _this.cardfailetoload = true;
    }
    //var cardElement = elements.create('card');
    _cardElement.mount('#card-element');
    /** ATTACHED CARD ELEMENTS */

    /** CARD ELEMENT VALIDATION */
    _cardElement.on('change', function(event) {
        if (event.error) {
          console.log('Card Error :' + event.error.message);
          $('#carderror').html('<small style="color:red"><sup>*</sup>' + event.error.message + '</small>');
          $('#carderror').show();
          //this.cardError = true;

          //_CardValidate = false;
        } else {
          $('#carderror').hide();
          //_CardValidate = true;
        }
    });

	
}
</script>
<style>
.modal-errorbilling {
  max-width: 520px;
  margin: 0 auto;
}
#modaltermofbilling .termofbilling {
  overflow-y: scroll;
  height: 300px;
  width: 100%;
  /*border: 1px solid #525f7f;*/
  padding: 10px;
}

  .centerform {
    width: 90%;
    margin: 0 auto;
  }

@media (min-width: 1024px) {
  .centerform {
    width: 50%;
    margin: 0 auto;
  }
}
.ccmonth {
  width: 50% !important;
}
.ccyear {
  width: 50% !important;
  padding-left: 10px;
}
#usrsetupcc .card-border {
  max-width: 95%;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #525f7f;
  border-radius:4px;
  padding-top:8px;
  /*padding: 8px 12px 6px 12px;
  margin:8px 0px 4px -1px;
  */
  height:36px;
  box-sizing: border-box;
  transform: translateZ(0);
  -webkit-user-select:text;
}

.termsx {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  width: 100%;
  border: 1px solid #525f7f;
  padding: 10px;
  background-color: #FFF;
}

.termsx p, .termsx span {
  color: #525f7f !important;
}

.termsx a,.termsx a:hover {
  color: #942434 !important;
}

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/*border-radius: 10px;*/
	background-color: gray;
  opacity: 0.5;
}

#style-2::-webkit-scrollbar
{
	width: 12px;
	background-color: #525f7f;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #525f7f;
}

.card-wizard .disabled {
    display: block;
}

.termsx p.bold {
    font-weight: bold;
}

</style>
