<template>
    <div>
        <div class="row">
           
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 style="font-size: 40px; font-weight: 700; line-height: 48px; " class="mb-0 mt-5">Sales Account List</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                <p>Add, edit or remove Sales Representative, Account Executive or Account Referral here.</p>
            </div>
            <!--<div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div>-->
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        
         <div class="row">
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <card>

                    <div class="row">
                        <div class="col-12">
                            <div id="txtSearchBox" class="pull-left" >                
                                <base-input>
                                    <el-input
                                        type="search"
                                        class="mb-3 search-input input__sales__list"
                                        clearable
                                        prefix-icon="el-icon-search"
                                        placeholder="Search"
                                        v-model="searchQuery"
                                        aria-controls="datatables"
                                        @keyup.native.enter="searchKeyWord"
                                        @clear="searchKeyWord"
                                    >
                                    </el-input>
                                </base-input>
                            </div>
                            <div class="pull-right">  
                                <base-button size="sm" style="height:40px" v-if="this.$global.settingMenuShow_create" @click="AddEditSales('')">
                                    <i class="fas fa-plus-circle"></i> Add Sales
                                </base-button>
                            </div>

                        </div>
                    </div>

                    <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(ProcessAddEditSales(''))">
                    <div id="showAddEditSales" class="row collapse">
                         <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 

                         <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                            name="Full Name"
                            rules="required"
                            v-slot="{ passed, failed, errors }"
                            >   
                            <base-input
                                label="Full Name"
                                type="text"
                                placeholder="Input Sales Name"
                                addon-left-icon="far fa-id-badge"
                                v-model="ClientFullName"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                            </ValidationProvider>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                            name="email"
                            rules="required|email"
                            v-slot="{ passed, failed, errors }"
                            >    
                            <base-input
                                label="Email"
                                type="email"
                                placeholder="Input Sales Email"
                                addon-left-icon="fas fa-envelope"
                                v-model="ClientEmail"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                            </ValidationProvider>
                          </div>

                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <base-input
                                label="Password"
                                type="password"
                                placeholder="Input Password"
                                addon-left-icon="fas fa-key"
                                v-model="ClientPass[0]"
                                >
                            </base-input>
                          </div>

                           <div class="col-sm-6 col-md-6 col-lg-6">
                            <!-- <base-input
                                label="Phone Number"
                                type="text"
                                placeholder="Input Sales Phone"
                                addon-left-icon="fas fa-phone-alt"
                                v-model="ClientPhone"
                                class="phonenum"
                                >
                            </base-input> -->
                            <label>Phone Number</label>

                            <VuePhoneNumberInput :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" @update="onPhoneUpdate" />
                          </div>
                          <!-- FOR ROLE-->
                          <div class="col-sm-3 col-md-3 col-lg-3" v-if="false">
                                <div class="form-group has-label pull-left">
                                    <label>Select Sales Title</label>
                                        <el-select
                                            class="select-primary"
                                            size="large"
                                            placeholder="Select Role"
                                            v-model="selects.roleSelected"
                                            @change="onRoleChange(0);"
                                        >
                                        
                                            <el-option
                                                v-for="option in selects.roleType"
                                                class="select-primary"
                                                :value="option.id"
                                                :label="option.role_name"
                                                :key="option.id"
                                            >
                                            <i :class="option.role_icon"></i> {{ option.role_name }}
                                            </el-option>
                                        </el-select>
                                </div>
                          </div>
                            
                          <!-- FOR ROLE-->
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <base-button native-type="submit" size="sm" class="pull-right" id="btnSave" style="height:40px" >
                                Save
                              </base-button>
                              <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditSales('')">
                                Cancel
                              </base-button>
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div> 
                     </div>
                    </form>
                    </ValidationObserver>
                    

                     <div class="row">
                         <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table @expand-change="onExpandClick" :data="queriedData" @sort-change="sortdynamic" :row-class-name="tableRowClassName" ref="tableData" :default-sort="{prop: 'full_name', order: 'ascending'}">
                                    <template #empty>
                                        <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
                                    </template>
                                    <el-table-column  min-width="160" prop="full_name" sortable="custom" label="Full Name">
                                        <div slot-scope="props">
                                              {{props.row.name}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="200" prop="email" sortable="custom"  label="E-mail">
                                        <div slot-scope="props">
                                              {{props.row.email}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="120" prop="phone" sortable="custom" label="Phone">
                                        <div slot-scope="props">
                                              {{props.row.phonenum}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="150" label="Permission">
                                        <div slot-scope="props" v-html="SetRoleUser(props.row.role_id)">
                                            
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="120"  prop="created_at" sortable="custom" align="center" label="Created">
                                        <div slot-scope="props">
                                              {{props.row.created_at}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column min-width="120" align="center" label="Actions">
                                            <div slot-scope="props">
                                                <el-tooltip
                                                    content="Stripe Account Status"
                                                    effect="light"
                                                    :open-delay="300"
                                                    placement="top"   
                                                >

                                                    <base-button
                                                        class="edit btn-link"
                                                        type="warning"
                                                        size="sm"
                                                        icon
                                                        >
                                                        <i class="fas fa-university" :style="chkSalesStatus(props.row.status_acc)"></i>
                                                    </base-button>
                                                </el-tooltip>

                                                <el-tooltip
                                                    content="Edit"
                                                    effect="light"
                                                    :open-delay="300"
                                                    placement="top"   
                                                >
                                                    <base-button
                                                        @click.native="rowClicked(props.row)"
                                                        class="edit btn-link"
                                                        type="neutral"
                                                        size="sm"
                                                        icon
                                                        >
                                                    <i class="fa-solid fa-pen-to-square" style="color:gray"></i>
                                                    </base-button>
                                               </el-tooltip>

                                                <el-tooltip
                                                    content="Referral link"
                                                    effect="light"
                                                    :open-delay="300"
                                                    placement="top"   
                                                >
                                                    <base-button
                                                        class="edit btn-link"
                                                        type="neutral"
                                                        size="sm"
                                                        icon
                                                        v-if="props.row.status_acc == 'completed'"
                                                         @click.native="poprefcode(props.row.referralcode,props.row.id)"
                                                        >
                                                        <i class="fas fa-link" style="color:gray"></i>
                                                    </base-button>
                                                 </el-tooltip>

                                                <el-tooltip
                                                    content="Remove"
                                                    effect="light"
                                                    :open-delay="300"
                                                    placement="top"   
                                                >

                                                <base-button
                                                    v-if="$global.settingMenuShow_delete && userData.id != props.row.id"
                                                    @click.native="handleDelete(props.$index, props.row)"
                                                    class="remove btn-link"
                                                    type="danger"
                                                    size="sm"
                                                    icon
                                                    >
                                                    <i class="fa-solid fa-circle-x" style="color: red;"></i>
                                                </base-button>

                                                </el-tooltip>

                                            </div>
                                    </el-table-column>

                                    <el-table-column  min-width="100%" type="expand">
                                            <!-- START EDIT AREA -->
                                            <template slot-scope="scope">
                                                    <ValidationObserver v-slot="{ handleSubmit }">
                                                    <form :id="'frmsales' + scope.row" @submit.prevent="handleSubmit(ProcessAddEditSales(scope.row))">
                                                    <div class="row">
                                                        
                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                                <ValidationProvider
                                                                name="Full Name"
                                                                rules="required"
                                                                v-slot="{ passed, failed, errors }"
                                                                > 
                                                                <base-input
                                                                    label="Full Name"
                                                                    type="text"
                                                                    placeholder="Input Sales Name"
                                                                    addon-left-icon="far fa-id-badge"
                                                                    v-model="scope.row.name"
                                                                    :error="errors[0]"
                                                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                                                    >
                                                                </base-input>
                                                                </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                                <ValidationProvider
                                                                name="email"
                                                                rules="required|email"
                                                                v-slot="{ passed, failed, errors }"
                                                                >
                                                                <base-input
                                                                    label="Email"
                                                                    type="email"
                                                                    placeholder="Input Sales Email"
                                                                    addon-left-icon="fas fa-envelope"
                                                                    v-model="scope.row.email"
                                                                    :error="errors[0]"
                                                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                                                    >
                                                                </base-input>
                                                                </ValidationProvider>
                                                        </div>

                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                            <base-input
                                                                label="Password"
                                                                type="password"
                                                                placeholder="Input Password"
                                                                addon-left-icon="fas fa-key"
                                                                v-model="ClientPass[scope.row.id]"
                                                                >
                                                            </base-input>
                                                        </div>

                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                                <!-- <base-input
                                                                    label="Phone Number"
                                                                    type="text"
                                                                    placeholder="Input Sales Phone"
                                                                    addon-left-icon="fas fa-phone-alt"
                                                                    v-model="scope.row.phonenum"
                                                                    class="phonenum"
                                                                    >
                                                                </base-input> -->
                                                                <label>Phone Number</label>

                                                                <VuePhoneNumberInput :defaultCountryCode="clientRowPhoneNumber.countryCode" v-model="clientRowPhoneNumber.number" @update="onRowPhoneUpdate" />
                                                        </div>

                                                        <!-- FOR ROLE-->
                                                        <div class="col-sm-3 col-md-3 col-lg-3" v-if="false">
                                                                <div class="form-group has-label pull-left">
                                                                    <label>Select Sales Title</label>
                                                                        <el-select
                                                                            class="select-primary"
                                                                            size="large"
                                                                            placeholder="Select Role"
                                                                            v-model="selects.roleSelected"
                                                                            @change="onRoleChange(scope.row.id);"
                                                                        >
                                                                        
                                                                            <el-option
                                                                                v-for="option in selects.roleType"
                                                                                class="select-primary"
                                                                                :value="option.id"
                                                                                :label="option.role_name"
                                                                                :key="option.id"
                                                                            >
                                                                            <i :class="option.role_icon"></i> {{ option.role_name }}
                                                                            </el-option>
                                                                        </el-select>
                                                                </div>
                                                        </div>
                                                        <!-- FOR ROLE-->
                                                        
                                                        <div class="col-sm-9 col-md-9 col-lg-9">
                                                            &nbsp;
                                                        </div>

                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <base-button native-type="submit" v-if="$global.settingMenuShow_update" size="sm" class="pull-right" :id="'btnSave' + scope.row.id" style="height:40px">
                                                                Save
                                                            </base-button>
                                                            <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditSales(scope.row)">
                                                                Cancel
                                                            </base-button>
                                                        </div>
                                                    </div>
                                                    </form>
                                                </ValidationObserver>
                                            </template>

                                            <!-- START EDIT AREA -->
                                    </el-table-column>

                            </el-table>
                         </div>
                     </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12" style="font-size:12px;">       
                            <div class="d-inline"><i class="fas fa-university" style="color:gray"></i> <span style="color:gray">Stripe Account not Setup</span></div>
                            <div class="d-inline pl-2"><i class="fas fa-university" style="color:red"></i> <span style="color:gray">Stripe Account incomplete</span></div>
                            <div class="d-inline pl-2"><i class="fas fa-university" style="color:blueviolet"></i> <span style="color:gray">Stripe Account in review</span></div>
                            <div class="d-inline pl-2"><i class="fas fa-university" style="color:green"></i> <span style="color:gray">Stripe Account completed</span></div>
                        </div>
                    </div>
                    
                    <template slot="footer">
                        <div class="tab-footer pull-right">
                                    <div class="pt-3">
                                        <p class="card-category">
                                            Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                                        </p>
                                    </div>
                                        <base-pagination
                                        class="pagination-no-border pt-4"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total="pagination.total"
                                        @input="changePage"
                                        >
                                        </base-pagination>
                                </div>
                    </template>

                 </card>
             </div>    
        </div>
        
        <!-- Modal LeadSpeek Embedded Code -->
        <modal :show.sync="modals.reflink" headerClasses="justify-content-center" modalContentClasses="customhead">
            <h4 slot="header" class="title title-up">Referral Link for Agency to Register</h4>

            <div v-if="refcode != ''">
                <p class="text-center">Your referral link: <strong>{{refcode}}</strong></p>
                <p class="text-center">
                    Please copy this link and use it to register your agency:
                </p>
                <div class="text-center">
                    <input class="refcodetxt" type="text" id="popreflink" :value="rootdomainurl + 'agency-register/' + refcode" readonly/>
                    <div>
                        <a href="javascript:void(0);" id="popreflinkLink" class="far fa-clipboard"
                            @click="copytoclipboard('popreflink')"> Copy</a>
                    </div>
                </div>
            </div>
            <div v-if="refcode == ''">
                <p class="text-center">
                    <a href="javascript:void(0);" @click="generateRefLink('popreflink')">Click here to generate your referral link.</a>
                </p>
            </div>

            <template slot="footer">
                <div class="container text-center pb-4">
                    <base-button @click.native="modals.reflink = false">Close Window</base-button>
                </div>
            </template>
        </modal>
        <!-- Modal LeadSpeek Embedded Code -->

    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, email} from "vee-validate/dist/rules";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Collapse, CollapseItem,Modal } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import VuePhoneNumberInput from '@/components/VuePhoneNumberInput';
extend("email", email);
extend("required", required);

export default {
    components: {
        Modal,
        Collapse,
        CollapseItem,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        BasePagination,
        VuePhoneNumberInput
    },
    data() {
        return {
            clientPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            },
            clientRowPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            },
             tableDataOri:[],
             tableData: [],
             fuseSearch: null,
             searchedData: [],
             searchQuery: '',
             pagination: {
                perPage: 10,
                currentPage: 1,
                //perPageOptions: [5, 10, 25, 50],
                total: 0,
                from: 0,
                to: 0,
            },
            modals: {
                helpguide: false,
                reflink: false,
            },
            companyID:'',
            refcode: '',
            rootdomainurl: '',
            activeusrid: '',

            ClientCompanyName: '',
            ClientFullName: '',
            ClientEmail: '',
            ClientPhone: '',
            ClientPass:[],
            ClientRole:[],
            ClientRoleDefault:'',

            selects: {
                roleSelected: '',
                roleType: [],
            },

            userData: '',
            defaultSales: false,
            defaultCustCare: false,
            ClientPermissionIcon: [
                {
                    IconRole: 'fa-solid fa-key',
                    RoleName: 'Super Admin',
                },
                {
                    IconRole: 'fa-solid fa-eye',
                    RoleName: 'View Only',
                },
                {
                    IconRole: 'fa-solid fa-book',
                    RoleName: 'Reporting',
                }
            ],   
            currSortBy: '',
            currOrderBy: '',
            expandedRow: null,
            isProcessingExpandRow: false,
        }
    },

    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result;
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        }
    },

    methods: {
        generateRefLink(idembeded) {
            this.$store.dispatch('CreateRefLink', {
                userID: this.activeusrid, 
            }).then(response => {
                if (response.result == 'success') {
                    this.refcode = response.refcode;
                    this.GetSalesList();
                }
            },error => {
            });

        },
        copytoclipboard(idembeded) {
            $('#' + idembeded).select();
            document.execCommand('copy');
            $('#' + idembeded + 'Link').html(' Copied!');

        },
        poprefcode(_refcode,_activeusrid) {
            this.refcode = _refcode;
            this.activeusrid = _activeusrid;
            this.modals.reflink = true;
        },
        changePage(event){
            //console.log(this.pagination,event)
            this.GetSalesList(this.currSortBy,this.currOrderBy)
        },
        onPhoneUpdate(payload){
           this.clientPhoneNumber.countryCode = payload.countryCode
           this.clientPhoneNumber.countryCallingCode = payload.countryCallingCode
        },
        onRowPhoneUpdate(payload){
           this.clientRowPhoneNumber.countryCode = payload.countryCode
           this.clientRowPhoneNumber.countryCallingCode = payload.countryCallingCode
        },
        chkSalesStatus(status) {
            if (status == "") {
                return "color:gray";
            }else if(status == "pending") {
                return "color:red";
            }else if (status == "inverification") {
                return "color:blueviolet";
            }else if (status == "completed") {
                return "color:green";
            }
        },
        sortcolumn: function(a,b) {
            return a.value - b.value;
        },
        sortdate: function(a,b) {
            return new Date(b.created_at) - new Date(a.created_at);
        },
         sortnumber: function(a,b) {
             return a - b;
        },
        SetRoleUser(roleid) {
            //console.log(roleid);
            if (roleid == '' || roleid == '0') {
                return "<i class='fas fa-exclamation-triangle'></i>";
            }else{
                for(let i=0;i<this.selects.roleType.length;i++) {
                    //console.log(this.selects.roleType[i]);
                    if(this.selects.roleType[i]['id'] == roleid) {
                        return  '<i class="' + this.selects.roleType[i]['role_icon'] + '" style="padding-right:10px"></i>' + this.selects.roleType[i]['role_name'];
                    }
                }
            }
        },
        onRoleChange(row) {
            this.ClientRole[row] = this.selects.roleSelected;
        },
        rowClicked(row) {
            if (this.isProcessingExpandRow) {
                return;
            }

            this.isProcessingExpandRow = true;

            if (this.expandedRow === row) {
                // Closing expand

                this.$refs.tableData.toggleRowExpansion(row, false);
                this.expandedRow = null;
            } else {
                // Closing all row expand 
                this.tableData.forEach(item => {
                    this.$refs.tableData.toggleRowExpansion(item, false);
                });

                // Exapand new row
                this.$refs.tableData.toggleRowExpansion(row, true);
                this.expandedRow = row;
            }

            this.isProcessingExpandRow = false;

            this.clientRowPhoneNumber.number = row.phonenum
            this.clientRowPhoneNumber.countryCode = row.phone_country_code
            this.clientRowPhoneNumber.countryCallingCode = row.phone_country_calling_code
            if(row.role_id == '' || row.role_id == '0') {
                this.selects.roleSelected = '';
            }else{
                this.selects.roleSelected = parseInt(row.role_id);
            }
        },
        tableRowClassName({row, rowIndex}) {
                row.index = rowIndex;
                return 'clickable-rows ClientRow' + rowIndex;
        },
        ClearAdminForm() {
            this.ClientCompanyName = '';
            this.ClientFullName = '';
            this.ClientEmail = '';
            this.ClientPhone = '';
            this.resetPhone()
        },
        AddEditSales(id) {
            this.resetPhone()
            $('#showAddEditSales' + id).collapse('show');
        },
        resetPhone(){
            this.clientPhoneNumber={
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            }
        },
        CancelAddEditSales(id) {
            if(id == '') {
              this.ClearAdminForm();
              this.ClientPass[0] = '';
              $('#showAddEditSales' + id).collapse('hide');
            }else{
                this.ClientPass[id.id] = '';
                this.$refs.tableData.toggleRowExpansion(id);
                this.GetSalesList(this.currSortBy,this.currOrderBy);
            }
            
        },
        ProcessAddEditSales(id) {
            if(this.ClientFullName != '' && this.ClientEmail != '' && id == '') {
                /** PROCESS ADD / EDIT ORGANIZATION */
                if(id == '') {
                    var _defaultAdmin = "F";
                    var _defaultCustCare = "F";

                    const regex = /^\s*$/;
                    if (regex.test(this.ClientFullName)) {
                        this.$notify({
                            type: 'danger',
                            message: 'Full Name can not be only spaces',
                            icon: 'fas fa-exclamation'
                        });
                        $('#btnSave' + id).attr('disabled',false);
                        $('#btnSave' + id).html('Save'); 
                        return; 
                    }

                    $('#btnSave' + id).attr('disabled',true);
                    $('#btnSave' + id).html('Processing...'); 
                    /** CREATE CLIENT */
                    this.$store.dispatch('CreateClient', {
                        companyID: this.companyID,
                        idsys: this.$global.idsys,
                        userType:'sales',
                        ClientCompanyName: '',
                        ClientFullName: this.ClientFullName,
                        ClientEmail: this.ClientEmail,
                        ClientPhone: this.clientPhoneNumber.number,
                        ClientPhoneCountryCode: this.clientPhoneNumber.countryCode,
                        ClientPhoneCountryCallingCode: this.clientPhoneNumber.countryCallingCode,
                        ClientPass: this.ClientPass[0],
                        ClientRole: this.selects.roleType[0]['id'],
                        defaultAdmin: _defaultAdmin,
                        customercare: _defaultCustCare,
                    }).then(response => {
                        //console.log(response[0]);
                        if (response.result == 'success') {
                            this.tableData.push(response.data[0]);
                            this.initialSearchFuse();
                            this.ClearAdminForm();
                            this.ClientPass[0] = '';
                            this.ClientRole[0] = this.selects.roleType[0]['id'];
                            $('#showAddEditSales' + id).collapse('hide');
                            $('#btnSave' + id).attr('disabled',false);
                            $('#btnSave' + id).html('Save'); 

                            this.$notify({
                                type: 'success',
                                message: 'Data has been added successfully',
                                icon: 'far fa-save'
                            });  
                        }else{
                            $('#btnSave' + id).attr('disabled',false);
                            $('#btnSave' + id).html('Save'); 

                            this.$notify({
                                type: 'primary',
                                message: response.message,
                                icon: 'fas fa-bug'
                            });
                        }
                    },error => {
                        $('#btnSave' + id).attr('disabled',false);
                        $('#btnSave' + id).html('Save'); 

                        this.$notify({
                            type: 'primary',
                            message: 'Server might be busy please try again later',
                            icon: 'fas fa-bug'
                        });
                    });
                    /** CREATE CLIENT */
                }
                /** PROCESS ADD / EDIT ORGANIZATION */
            }else{
                if ((id.name != '' && id.email != '') && (typeof id.name != 'undefined' && typeof id.email != 'undefined')) {
                    $('#btnSave' + id.id).attr('disabled',true);
                    $('#btnSave' + id.id).html('Processing...');

                    const regex = /^\s*$/;
                    if (regex.test(id.name)) {
                        this.$notify({
                            type: 'danger',
                            message: 'Full Name can not be only spaces',
                            icon: 'fas fa-exclamation'
                        });
                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save');
                        return; 
                    }

                    /** UPDATE CLIENT */
                    this.$store.dispatch('UpdateClient', {
                        companyID: id.company_id,
                        idsys: this.$global.idsys,
                        ClientID: id.id,
                        ClientFullName: id.name,
                        ClientEmail: id.email,
                        ClientPhone: this.clientRowPhoneNumber.number,
                        ClientPhoneCountryCode: this.clientRowPhoneNumber.countryCode,
                        ClientPhoneCountryCallingCode: this.clientRowPhoneNumber.countryCallingCode,
                        ClientPass: this.ClientPass[id.id],
                        ClientRole: (typeof(this.ClientRole[id.id]) == "undefined")?this.selects.roleType[0]['id']:this.ClientRole[id.id],
                        defaultAdmin: id.defaultadmin,
                        customercare: id.customercare,
                        action: 'administrator',
                    }).then(response => {
                        //console.log(response[0]);
                        for(let i=0;i<this.tableData.length;i++) {
                            if(this.tableData[i]['id'] == id.id) {
                                if (typeof(this.ClientRole[id.id]) !== 'undefined') {
                                    this.tableData[i]['role_id'] = this.ClientRole[id.id];
                                }
                            }
                        }

                        /** UPDATE USER LOGIN STORAGE DATA */
                        if (id.id == this.userData.id) {
                            this.userData.email = id.email;
                            this.userData.name = id.name;
                            this.userData.phonenum = id.phonenum;
                            this.userData.defaultadmin = id.defaultAdmin;
                            
                            const updatedData = {
                                email: id.email,
                                name: id.name,
                                phonenum: id.phonenum,
                                defaultadmin: id.defaultAdmin
                            }

                            this.$store.dispatch('updateUserData', updatedData);
                        }
                        /** UPDATE USER LOGIN STORAGE DATA */

                        this.$refs.tableData.toggleRowExpansion(id);
                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save'); 
                        this.GetSalesList();
                        this.$notify({
                            type: 'success',
                            message: 'Data has been updated successfully',
                            icon: 'far fa-save'
                        });  
                    },error => {
                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save'); 
                        
                        if(error.status === 422){
                            this.$notify({
                                type: 'primary',
                                message: error.data.message,
                                icon: 'fas fa-bug'
                            });

                            return;
                        }

                        this.$notify({
                            type: 'primary',
                            message: 'Server might be busy please try again later',
                            icon: 'fas fa-bug'
                        }); 
                    });
                    /** UPDATE CLIENT */
                }
            }
        },
        GetRoleList(id,typereq) {
            this.$store.dispatch('GetRoleList', {
                    companyID: this.companyID,
                    getType:typereq,
                    roleID:id,
                }).then(response => {
                    //console.log(response[0].id);
                    this.ClientRole[0] = parseInt(response[0].id);
                    this.selects.roleSelected = parseInt(response[0].id);
                    this.selects.roleType = response;
                },error => {
                    
                });
        },
        sortdynamic(column,prop,order) { 
            this.currSortBy = column.prop;
            this.currOrderBy = column.order;
            this.GetSalesList(column.prop,column.order);
        },
        searchKeyWord() {
            this.pagination.currentPage = 1;
            this.GetSalesList(this.currSortBy,this.currOrderBy);
        },
        GetSalesList(sortby,order) {
            var _sortby = '';
            var _order = '';
            var _searchkey = '';

            if (typeof(sortby) != 'undefined') {
                _sortby = sortby;
            }
            if (typeof(order) != 'undefined') {
                _order = order;
            }

            if (this.searchQuery != '') {
                _searchkey = this.searchQuery;
            }

            /** GET CLIENT LIST */
            this.tableData = [];
            $('.el-table__empty-text').html('<i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...');
            this.$store.dispatch('GetClientList', {
                companyID: this.companyID,
                idsys: this.$global.idsys,
                userType:'sales',
                sortby: _sortby,
                order: _order,
                searchkey: _searchkey,
                page:this.pagination.currentPage,
            }).then(response => {
                this.pagination.currentPage = response.current_page? response.current_page : 1
                this.pagination.total = response.total ?response.total : 0
                this.pagination.lastPage = response.last_page ? response.last_page : 2
                this.pagination.from = response.from ? response.from : 0
                this.pagination.to = response.to ? response.to : 2
                
                if (response.data.length == 0) {
                    $('.el-table__empty-text').html('No Data');
                }else{
                    this.tableData = response.data
                    this.initialSearchFuse()
                }
                this.GetRoleList('','getrole');
               
            },error => {
                
            });
            /** GET CLIENT LIST */
        },

        initialSearchFuse() {
            // Fuse search initialization.
            // this.fuseSearch = new Fuse(this.tableData, {
            //     keys: ['name','email','phonenum','created_at'],
            //     threshold: 0.1
            // });
        },

        handleDelete(index, row) {
            //console.log('Row: ' + index);
            swal.fire({
                title: 'Are you sure want to delete this?',
                text: `You won't be able to revert this!`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                    /** REMOVE ORGANIZATION */
                    
                        this.$store.dispatch('RemoveClient', {
                            companyID: row.company_id,
                            userID: row.id,
                        }).then(response => {
                            //console.log(response)
                            this.deleteRow(row);
                            swal.fire({
                                title: 'Deleted!',
                                text: `You deleted ${row.name}`,
                                icon: 'success',
                                confirmButtonClass: 'btn btn-default btn-fill',
                                buttonsStyling: false
                            });
                        },error => {
                            
                        });
                        
                    /** REMOVE ORGANIZATION */    
                }
        });
        },
        deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
        }
        },
        onExpandClick(row){
            this.rowClicked(row);
        }

    },

    mounted() {
        this.rootdomainurl = 'https://' + window.location.hostname + '/';
        this.userData = this.$store.getters.userData;
        this.companyID = this.userData.company_id;
        this.GetSalesList();
        // $('.phonenum input').usPhoneFormat({
        //     format: 'xxx-xxx-xxxx',
        // });
    },

    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        // searchQuery(value) {
        //     let result = this.tableData;
        //     var temp = Array();
        //     if (value !== '') {
        //         result = this.fuseSearch.search(this.searchQuery);
        //         for(let i=0;i<result.length;i++) {
        //             temp.push(result[i].item);
        //             //console.log(result[i].item);
        //         }

        //         if (result.length == 0) {
        //             if (this.tableData.length > 0) {
        //                 this.tableDataOri = [];
        //                 this.tableDataOri = this.tableData;
        //             }
        //             this.tableData = [];
        //         }
                
        //     }else{
        //         this.tableData = this.tableDataOri;
        //     }
        //     this.searchedData = temp;
        // }
    },
    
}
</script>
<style>
.refcodetxt {
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  background-color: transparent; /* Make background transparent */
  padding: 0; /* Remove padding if needed */
  margin: 0; /* Remove margin if needed */
  font-family: inherit; /* Inherit font */
  font-size: inherit; /* Inherit font size */

  width: 100%; /* Set width as needed */
  text-align: center; /* Center align text */
}
.refcodetxt:hover{
    cursor: not-allowed;
}
.clickable-rows td {
    cursor: pointer;
}
/* .clickable-rows .el-table, .el-table__expanded-cell {
    background-color:#1e1e2f;
} */
.clickable-rows tr .el-table__expanded-cell {
    cursor: default;
}

.input__sales__list .el-input__inner {
    padding-left: 30px;
    padding-right: 30px;
}

.el-table_1_column_4 {
    text-align: center !important;
}
</style>